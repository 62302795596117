/* Pour les écrans plus petits que 768px */
@media screen and (max-width: 768px) {
  .container {
    width: 100%;

    position: absolute;
    top: 10px;
    margin: 0;
    padding: 0;

    display: flex;

    align-items: center;
    flex-direction: column;

    color: rgb(237, 237, 237);
    font-size: 25px;
  }
  .character {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgb(31, 31, 35);
    border: 1px solid rgb(0, 218, 181);
    border-radius: var(--small-radius);

    height: 200px;
    max-width: 100%;

    font-size: 100px;
  }

  .text-container {
    margin: 10px;
    width: 100%;
    display: flex;
    align-items: center;

    flex-direction: column;
  }
}

/* Pour les écrans plus grands que 768px */
@media screen and (min-width: 768px) {
  .character {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 20px;
    background-color: rgb(31, 31, 35);
    border: 1px solid rgb(0, 218, 181);
    border-radius: var(--small-radius);
    height: 400px;

    font-size: 300px;
  }

  .container {
    width: 100%;

    margin: 0;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: rgb(237, 237, 237);
    font-size: 25px;
  }

  .text-container {
    margin: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.container .text-container #input[type="text"] {
  text-transform: lowercase;
}

a {
  cursor: none;
  color: rgb(215, 215, 215);
  text-decoration: none;
}
a:visited {
  color: inherit;
  text-decoration: none;
}

.text-container input {
  cursor: none;

  outline: none;
  border-radius: var(--small-radius);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 200px;
  height: 50px;
  margin: 0;

  font-size: 40px;
  text-align: center;
}

.text-container img:hover {
  transform: scale(1.05);
}

.brightness {
  filter: invert(100%) brightness(0.5);
}
