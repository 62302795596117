@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway/Raleway-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway/Raleway-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway/Raleway-Bold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway/Raleway-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Raleway";
  src: url("../fonts/Raleway/Raleway-ExtraLight.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "MOBO";
  src: url("../fonts/MOBO/MOBO.otf");
  font-weight: normal;
}
