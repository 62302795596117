.container {
  color: rgb(215, 215, 215);
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container ul {
  margin: 0;
  padding: 0;

  animation: pop 0.5s forwards;
}
.container a {
  cursor: none;
  color: rgb(215, 215, 215);
  text-decoration: none;
}
.container a:visited {
  color: inherit;
  text-decoration: none;
}

.container li {
  font-family: "Raleway";
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--small-radius);

  width: 300px;
  height: 50px;
  margin-top: 15px;

  list-style: none;
}

.container li:hover {
  border-radius: var(--small-radius);

  text-align: center;
  list-style: none;
  box-shadow: 0px 1px 10px rgba(144, 144, 144, 0.597);
}
.hiragana {
  color: rgb(0, 234, 255);

  background-color: rgb(19, 76, 90);
}

.katakana {
  color: rgb(0, 255, 136);
  background-color: rgba(19, 139, 89, 0.81);
}

.kanji {
  color: rgb(238, 255, 0);
  background-color: rgb(113, 122, 44);
}

.slide-left {
  animation: slide-left 1s ease-in;
}

@keyframes slide-left {
  0% {
    opacity: 1;
  }
  100% {
    transform: translateX(2000%);
    opacity: 0;
  }
}
