/* Pour les écrans plus petits que 768px */
@media screen and (max-width: 768px) {
  .logo {
    border-radius: 70px;
    background-color: rgb(41, 41, 41);

    width: 200px;
    margin: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-gradient {
    width: 400px;
    margin: 0px;
    border-radius: 70px;

    font-size: 40px;
    font-family: "MOBO";

    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
      -45deg,
      #ee7752,
      #e73c7e,
      #23a6d5,
      #23d5ab,
      rgb(243, 243, 0)
    );
    background-size: 800% 800%;
    background-clip: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 7s ease-in-out infinite;
  }
}

/* Pour les écrans plus grands que 768px */
@media screen and (min-width: 768px) {
  .logo {
    border-radius: 70px;
    background-color: rgb(41, 41, 41);

    width: 400px;
    margin: 30px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-gradient {
    width: 400px;
    margin: 0px;
    border-radius: 70px;

    font-size: 80px;
    font-family: "MOBO";

    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(
      -45deg,
      #ee7752,
      #e73c7e,
      #23a6d5,
      #23d5ab,
      rgb(243, 243, 0)
    );
    background-size: 800% 800%;
    background-clip: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 7s ease-in-out infinite;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 00% 00%;
  }
}
