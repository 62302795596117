@media screen and (max-width: 768px) {
  .App {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    overflow: hidden;
  }
}

/* Pour les écrans plus grands que 768px */
@media screen and (min-width: 768px) {
  .App {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
