@import "./fonts";
@import "./theme";

html,
body,
#root {
  font-family: "Raleway", "MOBO", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-size: 25px;
  color: white;

  background-color: rgb(23, 23, 24);
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  cursor: none;
  user-select: none;
}
