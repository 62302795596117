.counter {
  opacity: 0;
  animation: fadeout 1s cubic-bezier(0.84, 0.07, 0.83, 0.67) 0s 3;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
