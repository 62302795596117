/* Pour les écrans plus petits que 768px */
@media screen and (max-width: 768px) {
}

/* Pour les écrans plus grands que 768px */
@media screen and (min-width: 768px) {
  .custom-cursor {
    pointer-events: none;
    position: absolute;
    z-index: 999;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    border: 2px solid rgba(101, 138, 141, 0.824);
  }
}
