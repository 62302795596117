.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  width: 150px;
  line-height: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container li {
  display: flex;
  justify-content: center;
  width: 150px;
  border-radius: var(--small-radius);
  line-height: 2;
  margin-bottom: 10px;
  border: 1px solid white;
}

.very-easy:hover {
  border: 1px solid rgb(0, 255, 72);
  box-shadow: 0px 0px 2px 2px rgb(0, 255, 72);
}
.easy:hover {
  border: 1px solid rgb(0, 255, 200);
  box-shadow: 0px 0px 2px 2px rgb(0, 255, 200);
}
.medium:hover {
  border: 1px solid rgb(208, 255, 0);
  box-shadow: 0px 0px 2px 2px rgb(208, 255, 0);
}
.hard:hover {
  border: 1px solid rgb(255, 174, 0);
  box-shadow: 0px 0px 2px 2px rgb(255, 174, 0);
}
.very-hard:hover {
  border: 1px solid rgb(255, 0, 0);
  box-shadow: 0px 0px 2px 2px rgb(255, 0, 0);
}
.godlike:hover {
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0px 0px 7px 7px rgb(246, 255, 0);
}
