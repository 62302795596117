.container {
  margin: 20px 0px;
  width: 400px;
  height: 50px;

  display: flex;

  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.hint-button,
.change-char-button {
  height: 100%;
  display: flex;
  align-items: center;
}

.hint-button img,
.change-char-button img {
  margin: 0 10px;
  width: 50px;
  height: 50px;

  filter: invert(100%) brightness(0.5);
}

.hint {
  border: 2px solid rgb(0, 147, 134);
  color: rgb(0, 147, 134);
  border-radius: var(--small-radius);
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 60px;
  width: auto;
  height: 100%;
  font-size: 30px;
}
